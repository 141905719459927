const Header = () => {
    return (
        <div className="header">
            <div className="glat-logo"></div>
            <h1>Greater Love</h1>
            <h1>Apostolic Temple</h1>
        </div>
    )
}

export default Header;