import { Fragment, useState } from "react";
import {Routes, Route} from "react-router-dom";
import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import Header from './Header';
import HomeScreen from "./HomeScreen";
import GiveNowForm from "./GiveNowForm";
import ReviewDonation from "./ReviewDonation";
import Footer from "./Footer";
import GiveNow from "./GiveNow";

const App = () => {
  const [donationForm, setDonationForm] = useState({});
  const [formTotal, setFormTotal] = useState(0);

  const resetData = () => {
    setFormTotal(0);
    setDonationForm({});
  }

  return (
    <Fragment>
      <div className="app">
      <PayPalScriptProvider options={{ "client-id": "ATddWpfBpQ0dH8oTZE_QfTWHQSd9DkeCNlIV_Kc6pYFO8nLckVxBACs39Nxe6-xtsok7cyIn2dGJ9z4Q" }} >
        <Header />
        <Routes>
          <Route 
            path="*" 
            element={
              <HomeScreen 
                resetData={resetData}
              />
            }
          />
          <Route 
            exact path="/" 
            element={
              <HomeScreen 
                resetData={resetData}
              />
            }
          />
          <Route 
            path="/give-now-form" 
            element={
              <GiveNowForm 
                donationForm={donationForm} 
                setDonationForm={setDonationForm}
                formTotal={formTotal}
                setFormTotal={setFormTotal}
              />
            }
          />
          <Route 
            path="/give-now" 
            element={
              <GiveNow 
                donationForm={donationForm} 
                setDonationForm={setDonationForm}
                formTotal={formTotal}
                setFormTotal={setFormTotal}
              />
            }
          />
          <Route 
            path="/review-donation" 
            element={
              <ReviewDonation 
                donationForm={donationForm} 
                formTotal={formTotal}
              />
            }
          />
        </Routes>
        <Footer />
        </PayPalScriptProvider>
      </div>
    </Fragment>
  )
};


  
export default App;


/*  const clearForm = () => {
    tempDepartments.map(dept => (
      dept.inputValue = 0
    ))
  }

  const handleInputChange = (value, id) => {
    tempDepartments.forEach((dept, i) => {
      if (dept.id === id) {
        tempDepartments[i].inputValue = value
      }
    })
    setDepartments(tempDepartments);
    updateTotal();
  }

  const updateTotal = () => {
    let tempTotal = 0;
    if (isMember) {
      departments.forEach((dept) => {
        if (dept.inputValue){
        tempTotal = Number(tempTotal) + Number(dept.inputValue);}
      })
    } else {
      departments.forEach((dept) => {
        if (dept.id <= 10 && dept.inputValue){
        tempTotal = Number(tempTotal) + Number(dept.inputValue);}
      })
    }
    setFormTotal(tempTotal.toFixed(2));
  }

  const logout = () => {
    setIsMember(false);
    setFormTotal(0.00);
    clearForm();
    setDepartments(tempDepartments);
}*/