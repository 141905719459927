import { useState } from "react";

const GiveNowFormList = ({authedUser, department, donationForm, setDonationForm}) => {
    const [notes, setNotes] = useState("");

    /*const validate = (e) => {
        let t = e.target.value;
        e.target.value = (t.indexOf(".") >= 0) ? (t.substr(0, t.indexOf(".")) + t.substr(t.indexOf("."), 3)) : t;
      }*/

    const handleNotes = (e) => {
        let value = e.target.value;
        setNotes(value);
        handleChange()
    }

    const handleChange = (e) => {
        let value = e.target.value;
        value === "" || value === "." ? value = 0 : value = e.target.value;
        let donation;
        department.id !== 10
        ? donation = {
            departmentID: department.id,
            deptName: department.name,
            user: authedUser,
            amount: value
        }
        : donation = {
            departmentID: department.id,
            deptName: department.name,
            user: authedUser,
            amount: value,
            userNotes: notes
        }

        let donations = {
            ...donationForm,
            [department.id]: donation
        }
        setDonationForm(donations);
    }

    return (
        <div className="form-list-item">
            {department.id === 10 
                ? <p><input name="specify-text"
                    maxLength="30"
                    onChange={handleNotes}
                    className="form-input-specify"
                    placeholder={department.id in donationForm ? donationForm[department.id].userNotes : "Specify"}
                /> </p>
                : <p>{department.name}</p>
            }
            <span>$</span>
            <input 
                onKeyPress={(e) => !/[0-9]|\.\./.test(e.key) && e.preventDefault()} 
                onChange={handleChange} 
                maxLength="7"
                name="form-input" 
                id={department.id} 
                className="form-input-value"
                placeholder={department.id in donationForm ? donationForm[department.id].amount : "0.00"} />
        </div>     
    )
}

export default GiveNowFormList;