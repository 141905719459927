import { useEffect } from "react";
import { Link } from "react-router-dom";

const HomeScreen = ({resetData}) => {
    window.document.body.style.zoom = 1;
    
    useEffect(() => {
        resetData();
    }, [])
    
    return (
        <div className="splash-page">
            <div className="splash-btn">
                <Link to={"give-now"} ><button>Give Now</button></Link>
            </div>
        </div> 
    )
}

export default HomeScreen;