import React from 'react';
import { useEffect } from "react";
import { Link } from "react-router-dom";
import GiveNowFormList from "./GiveNowFormList";
import GiveNowList from './GiveNowList';

const GiveNow = ({donationForm, setDonationForm, formTotal, setFormTotal}) => {
    let departments = [
        {id: 1, name: "General Offering", dept: "gen-off"},
        {id: 2, name: "Tithes", dept: "tithes"},
        {id: 3, name: "Christian Education", dept: "ch-ed"},
        {id: 4, name: "Building Fund", dept: "bfund"},
        {id: 5, name: "Tree of Life Ministries Outreach", dept: "tol"},
        {id: 6, name: "Men's Ministry", dept: "men"},
        {id: 7, name: "Women's Ministry", dept: "women"},
        {id: 8, name: "Youth Ministry", dept: "youth"},
        {id: 9, name: "Pastor's Aide", dept: "pa"},
        {id: 10, name: "Specify", dept: "specify"},
    ];

    window.document.body.style.zoom = 1;
    useEffect(() => {
        console.log(donationForm)
        let donationTotals = 0
        Object.values(donationForm).forEach((donation) => {
            if (typeof Number(donation.amount) === "number") {
                donationTotals = Number(donation.amount) + Number(donationTotals)
            } 
        });
        setFormTotal(donationTotals.toFixed(2));        
    }, [donationForm])

    return (
        <div className="form-component red-borde">
            <h2>Enter Contributions</h2>
            <div className="form-container red-borde">
                {departments.map((department, index) => (
                    <GiveNowList key={index} department={department} donationForm={donationForm} setDonationForm={setDonationForm} /> 
                ))}
                <div className="form-total">
                    <h3>Total Contributions</h3>
                    <p>{`$ ${formTotal}`}</p>
                </div>
                <Link to={"/review-donation"} ><button className="form-review-btn">Review Donation</button></Link>
            </div>
            <Link to="/"><p>Start over.</p></Link>
        </div>
    )
}

export default GiveNow;