const ReviewDonationList = ({department}) => {
    return (
        <div className="review-item">
            {department.departmentID === 10
                ? <p>{department.userNotes}</p>
                : <p>{department.deptName}</p>
            }
            <span>$</span><span className="review-amount">{Number(department.amount).toFixed(2)}</span>
        </div>      
    )
}

export default ReviewDonationList;