import { useState, useEffect, useRef } from "react";
import { PayPalButtons } from "@paypal/react-paypal-js";

// There are 3 ways to keep the amount from being stale in our createOrder callback:
// 1. Use amountRef.current to keep track of amount changes
// 2. Use the forceReRender prop (which causes flashing)
// 3. Use the key property (which also causes flashing)

export default function ReactPayPal({formTotal, donationForm, resetData}) {
  const [paid, setPaid] = useState(false);
  const [error, setError] = useState(null);
  const descripArray = Object.values(donationForm);
  let descriptionString = [];
  descripArray.map((d) => 
    d.departmentID !== 10
      ? d.amount > 0 && descriptionString.push(d.deptName + ": $" + d.amount) 
      : d.amount > 0 && descriptionString.push(d.userNotes + ": $" + d.amount)
  );
  console.log(descriptionString)

  var FUNDING_SOURCES = ["paypal", "venmo", "card", "applepay"]

  const DonateButton = ({ currency, amount, fundingSource }) => {
    const amountRef = useRef(amount);
    useEffect(() => {
      amountRef.current = amount;
    }, [amount]);
  
    return (
      <PayPalButtons
        // forceReRender={[currency, amount]}
        style={{ color: "black", label: "donate" }}
        fundingSource={fundingSource}
        createOrder={(data, actions) => {
          return actions.order.create({
            purchase_units: [
              {
                amount: {
                  value: amountRef.current,
                  breakdown: {
                    item_total: {
                      currency_code: currency,
                      value: amountRef.current
                    } 
                  }  
                },
                items: [
                  {
                    name: JSON.stringify(descriptionString, null, " ").replace(/[\[\]']+/g,''),
                    description: "Donation Details",
                    quantity: "1",
                    unit_amount: {
                      currency_code: currency,
                      value: amountRef.current
                    },
                    category: "DONATION"
                  }
                ]
              }
            ],
            application_context: {
              shipping_preference: "NO_SHIPPING"
            }
          })
          .then((orderId) => {
            // Your code here after create the order
            return orderId;
          });
        }}
        onApprove={function (data, actions) {
          return actions.order.capture().then(function () {
            // Your code here after capture the order
            setPaid(true)
          });
        }}
        onError={function (data, actions) {
          // Your code if error
          setError(true)
        }}
      />
    );
  };

  if (paid) {
    return <div>Donation successful! <br></br>Check your email for confirmation.</div>;
  }

  // If any error occurs
  if (error) {
    return <div>Error Occurred in processing payment. Please try again.</div>;
  }

  // Default Render
  return (
    <div>
      <h4>Total Amount of Donation: ${formTotal}</h4>
      {
        FUNDING_SOURCES.map((fundingSource) => (
          <DonateButton currency="USD" amount={formTotal} fundingSource={fundingSource} />
        ))
      }
      
    </div>
  );
}