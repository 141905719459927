import React from "react";
import ReactDOMClient from "react-dom/client";
import App from "./components/App";
import {BrowserRouter as Router} from "react-router-dom";
import "./css/index.css";

const root = ReactDOMClient.createRoot(
    document.getElementById('root')
);

root.render(
    <React.StrictMode>
      <Router basename={process.env.PUBLIC_URL}>
            <App />
        </Router>
    </React.StrictMode>
);