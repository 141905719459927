import { useState } from "react";
import FormControl from '@mui/material/FormControl';
import InputAdornment from '@mui/material/InputAdornment';
import InputLabel from '@mui/material/InputLabel';
import Input from '@mui/material/Input';
import TextField from '@mui/material/TextField';
import OutlinedInput from '@mui/material/OutlinedInput';

const GiveNowList = ({department, donationForm, setDonationForm}) => {
    const [notes, setNotes] = useState("");

    /*const validate = (e) => {
        let t = e.target.value;
        e.target.value = (t.indexOf(".") >= 0) ? (t.substr(0, t.indexOf(".")) + t.substr(t.indexOf("."), 3)) : t;
      }*/

    const handleNotes = (e) => {
        let value = e.target.value;
        setNotes(value);
        handleChange()
    }

    const handleChange = (e) => {
        let value = e?.target?.value;
        value === "" || value === "." || !value ? value = 0 : value = e?.target?.value;
        let donation;
        department.id !== 10
        ? donation = {
            departmentID: department.id,
            deptName: department.name,
            amount: value
        }
        : donation = {
            departmentID: department.id,
            deptName: department.name,
            amount: value,
            userNotes: notes
        }

        let donations = {
            ...donationForm,
            [department.id]: donation
        }
        setDonationForm(donations);
    }

    return (
        <div className="form-list-item red-borde">
            {
                department.id === 10 
                ? <div className="specify-section red-borde">
                     <TextField
                        onChange={(e) => handleNotes(e)}
                        id="outlined-helperText"
                        label="Please Specify"
                        defaultValue={department?.id in donationForm && donationForm[department?.id]?.userNotes.length > 0 ? donationForm[department?.id]?.userNotes : null}        
                        sx={{ m: 1 }}
                    />
                    <TextField
                        onKeyPress={(e) => !/[0-9]|\.\./.test(e.key) && e.preventDefault()}
                        onChange={(e) => handleChange(e)} 
                        maxLength="7"
                        label="Amount"
                        id="outlined-start-adornment"
                        sx={{ m: 1}}
                        InputProps={{
                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                        }}
                    />
                </div>
                :  <TextField
                    onKeyPress={(e) => !/[0-9]|\.\./.test(e.key) && e.preventDefault()}
                    onChange={(e) => handleChange(e)} 
                    maxLength="7"
                    label={department?.name}
                    id="outlined-start-adornment"
                    sx={{ m: 1}}
                    fullWidth
                    InputProps={{
                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                    }}
                />
            }           
        </div>     
    )
}

export default GiveNowList;