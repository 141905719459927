import { Link } from "react-router-dom";
import { useState } from "react";
import ReviewDonationList from "./ReviewDonationList";
import ReactPayPal from "./ReactPayPal";
import { PayPalScriptProvider } from "@paypal/react-paypal-js";


const ReviewDonation = ({resetData, donationForm, formTotal}) => {
    const [checkout, setCheckout] = useState(false);
    const descripArray = Object.values(donationForm);
    let descriptionString = [];
    descripArray.map((d) => 
        d.amount > 0 && descriptionString.push(d.deptName + ": $" + d.amount)
    );

    return (
        <PayPalScriptProvider
            options={{
                "client-id": "ATddWpfBpQ0dH8oTZE_QfTWHQSd9DkeCNlIV_Kc6pYFO8nLckVxBACs39Nxe6-xtsok7cyIn2dGJ9z4Q",
                components: "buttons",
                "disable-funding": "venmo",
                currency: "USD"
            }} >
        <div className="review-pay-component">
        
            <h2>Review and Pay</h2>
            <div className="review-container">
                {descripArray.map((department) => department.amount > 0 && (
                    <ReviewDonationList key={department.departmentID} department={department} /> 
                ))}
                <div className="form-total">
                    <h3>Total Contributions</h3>
                    <p>{`$ ${formTotal}`}</p>
                </div>
                
                    <div className="pay-now-buttons-grid">
                        {(checkout === true) 
                            ? <div className="payment-div">
                                <ReactPayPal resetData={resetData} formTotal={formTotal} donationForm={donationForm} />
                            </div> 
                        :<div>
                            <button onClick={() => {setCheckout(true)}} className="checkout-button">Donate ${formTotal}</button>
                        </div>
                        }
                    </div>
            </div>
           
            <Link to="/"><p>Start over.</p></Link>
            
        </div>
        </PayPalScriptProvider>
    )
}

export default ReviewDonation;